<template>
  <div class="container-fluid">
    <div class="d-flex justify-content-center">
      <div class="card mt-5 border-white">
        <div class="card-body row">
          <h3 class="col-12 text-center">VIRTUAL LOTTO</h3>
          <form class="col-12" @submit.prevent="handleLogin">
            <div class="row justify-content-center">
              <div class="form-group text-left col-12">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Username"
                  v-model="user.username"
                  required
                />
              </div>
              <div class="form-group text-left col-12">
                <input
                  type="password"
                  class="form-control"
                  placeholder="Password"
                  v-model="user.password"
                  required
                />
              </div>
              <button type="submit" class="btn btn-primary">Login</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: {},
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/welcome");
    }
  },
  methods: {
    handleLogin() {
      this.$store.dispatch("auth/login", this.user).then(
        () => {
          this.$router.push("/welcome");
        },
        (error) => {
          console.log(error);
          this.$swal({
            icon: "error",
            title: "Login ไม่สำเร็จ",
          });
        }
      );
    },
  },
};
</script>